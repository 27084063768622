var tms_page_data = tms_page_data || {};
var utag_data = utag_data;
(function($) {
  Drupal.behaviors.ELC_GAEvents = {
    attach: function() {
      function waitForELCGAEvents() {
        if (window.ELCGAEventsReady === true) {
          addEventTriggers();
          addEcommerceTriggers();
        } else {
          setTimeout(waitForELCGAEvents, 1000); //1s
        }
      }
      waitForELCGAEvents();

      function addEventTriggers() {
        const clickDebouncePeriod = 200; //0.2s

        // Top nav registration link click
        $('.page-utilities__signin-text').click(function() {
          $(document).trigger({type: 'registerClick.btga', linkLocation: 'Top nav signin link'});
        });

        // New registration
        if ($.cookie('JUST_REGISTERED') && !$.cookie('registration_event_fired')) {
          $(document).trigger('registerSuccess.btga');
          $.cookie('registration_event_fired', 1);
        } else if (!$.cookie('JUST_REGISTERED') && $.cookie('registration_event_fired')) {
          $.cookie('registration_event_fired', '');
        }

        // Email subscription
        $('footer #email-signup__form').on('submit', function() {
          $(document).trigger({type: 'emailSubscription.btga', formLocation: 'Footer Link'});
        });
        $('body.section-special-offers-0 .main #email-signup__form').on('submit', function() {
          $(document).trigger({type: 'emailSubscription.btga', formLocation: 'Special Offers'});
        });

        // Social share
        $(document).on('click', '.social-share__link', function() {
          var serviceName = {
            facebook: 'Facebook',
            twitter: 'Twitter',
            pinterest: 'Pinterest'
          };
          for (var serviceClass in serviceName) {
            if ($(this).hasClass(serviceClass)) {
              $(document).trigger({type: 'socialShare.btga', serviceName: serviceName[serviceClass]});
            }
          }
        });

        // Add product to favourites
        $(document).on('click', '.js-add-to-favorites', _.debounce(function(e) {
          var skuBaseID = $(e.target).data('sku-base-id') || '';
          $(document).trigger({
            type: 'favouriteAdd.btga',
            buttonLocation: 'SPP',
            skuBaseID: skuBaseID
          });
        }, clickDebouncePeriod));

        // Write review button
        $(document).on('mousedown', '#BVRRRatingSummaryLinkWriteFirstID a, #BVRRRatingSummaryLinkWriteID a, .pr-snippet-write-review-link', function() {
          $(document).trigger('reviewWrite.btga');
        });

        // Review submit button
        $(document).on('mousedown', '#BVButtonSubmitID button, .pr-submit .pr-btn', function() {
          $(document).trigger('reviewSubmit.btga');
        });

        // Continue shopping button
        $('.return-link a, a.continue-shopping').on('click', function() {
          $(document).trigger('continueShopping.btga');
        });

        // Quick view button
        $(document).on('click', '.product_brief__button-panel', _.debounce(function(e) {
          var skuBaseId = $(e.target).data('sku-base-id');
          var prodId = $(e.target).data('product-id');

          if (!skuBaseId) {
            skuBaseId = $(this).closest('.product_brief__buttons-container').find('.product_brief__button--add-to-bag').data('sku-base-id');
          }

          $(document).trigger({
            type: 'productQuickview.btga',
            skuBaseID: skuBaseId,
            prodId: prodId
          });
        }, clickDebouncePeriod));

        // Product reviews filter
        $(document).on('click', '.BVDI_QTFilter input, .pr-rd-main-header-search-sort input[type=checkbox]', function() {
          $(document).trigger('reviewFilter.btga');
        });

        // Navbar click
        $('.page-navigation').on('click', 'a, h3', function() {
          var linkName = $(this)[0].innerText;

          // Find every a or h3 between the nav container and the clicked nav item and comma-concatenate their innerTexts
          var eventPath = '';
          if (this.matches('.level-1.menu-trigger')) {
            eventPath = this.innerText;
          } else {
            eventPath = $.map(navParentsUntil('.page-navigation', 'a, h3', this), function(n) {
              return n.innerText;
            }).join(',');
          }

          if (event.target.tagName.toLowerCase() === 'img') {
            var whichTout = '';
            var parent = event.target.closest('.nav-tout');
            if (parent.className.indexOf('nav-tout--') >= 0) {
              parent.className.split(' ').forEach(function(className) {
                if (className.indexOf('nav-tout--') >= 0) {
                  whichTout = ' ' + className.split('nav-tout--').pop();
                }
              });
            }
            linkName = 'navpromo' + whichTout;
          }

          $(document).trigger({
            type: 'navbarClick.btga',
            navbarName: 'Top Nav',
            linkName: linkName,
            eventPath: eventPath,
            eventClickedURI: $(this).attr('href'),
          });
        });

        // Add to cart buttons. Using click event instead of addToCart.success as the latter doesn't fire in all circumstances
        $(document).on('click', '.page-product .js-add-to-cart.product-full__add-button', _.debounce(function() {
          //SPP main Add to Bag button
          var skuBaseID = $(this).data('sku-base-id') || '';
          $(document).trigger({
            type: 'productAddToCart.btga',
            buttonLocation: 'SPP main Add to Bag button',
            skuBaseID: skuBaseID
          });
        }, clickDebouncePeriod));
        $(document).on('click', '.page-product .js-add-to-cart.spp-product__mini-bag-add-button', _.debounce(function() {
          var skuBaseID = $(this).data('sku-base-id') || '';
          $(document).trigger({
            type: 'productAddToCart.btga',
            buttonLocation: 'SPP Mini Bag Add to Bag button',
            skuBaseID: skuBaseID
          });
        }, clickDebouncePeriod));
        $(document).on('click', '.mpp .js-add-to-cart', _.debounce(function() {
          var skuBaseID = $(this).data('sku-base-id') || '';
          $(document).trigger({
            type: 'productAddToCart.btga',
            buttonLocation: 'MPP Add to Bag button',
            skuBaseID: skuBaseID
          });
        }, clickDebouncePeriod));
        $(document).on('click', '.quickshop .js-add-to-cart', _.debounce(function() {
          var skuBaseID = $(this).data('sku-base-id') || '';
          $(document).trigger({
            type: 'productAddToCart.btga',
            buttonLocation: 'MPP Quickview Add to Bag button',
            skuBaseID: skuBaseID
          });
        }, clickDebouncePeriod));

        // Order confirm page
        if ($('body#confirm').length) {
          $(document).trigger('orderConfirm.btga');
        }

        // Authorisation / Login
        if ($.cookie('JUST_SIGNIN') && !$.cookie('signin_event_fired')) {
          $(document).trigger('profileLogin.btga');
          $.cookie('signin_event_fired', 1);
        } else if (!$.cookie('JUST_SIGNIN') && $.cookie('signin_event_fired')) {
          $.cookie('signin_event_fired', '');
        }

        // Offer code activation
        $('form#offer_code').on('submit', function() {
          $(document).trigger({type: 'offerApply.btga', offerCode: $(this).find('input[name=OFFER_CODE]').val()});
        });
      }

      function getPageType() {
        var pageType;

        if (tms_page_data && tms_page_data.tms_page_info && tms_page_data.tms_page_info.page_name.pageType) {
          pageType = tms_page_data.tms_page_info.page_name.pageType;
        } else if (page_data) {
          if (page_data['catalog-spp']) {
            pageType = 'SPP';
          } else if (page_data['catalog-mpp'] || page_data['custom-mpp']) {
            pageType = 'MPP';
          }
        }

        return pageType;
      }

      // Like jQuery.parentsUntil() but with a twist - we can't just use parentsUntil here because the parent nav item
      // may not necessarily be a direct ancestor, but rather a pibling or cousin of some remove (e.g. the child of a grandparent)
      function navParentsUntil(container, selector, el) {
        var parents = [];

        $(el).parentsUntil(container).each(function() {
          if ($(this).is(selector)) {
            parents.push(this[0]);
          } else {
            $(this).children().each(function(i, child) {
              if ($(child).is(selector)) {
                parents.push(child);
              }
            });
          }
        });
        if (container === '.page-navigation' && $('.menu-reference.js-active')[0]) {
          parents.push($('.menu-reference.js-active a.level-1.menu-trigger')[0]);
        }

        return parents.reverse();
      }

      function addEcommerceTriggers() {
        var products = getProducts();
        var cartProducts = getCartProducts();
        var category, date, list, prodObj, cartVisitedGA, selectedPaymentInput, orderInfo, paymentOptionCode, paymentMethodMap, paymentOption;
        const scrollDebouncePeriod = 100; //0.1s

        if (tms_page_data && tms_page_data.tms_page_info && tms_page_data.tms_page_info.page_name) {
          category = getCategory(tms_page_data.tms_page_info.page_name);
        }

        // Product Teaser Impression
        $(window).on('load scroll', _.debounce(function() {
          var impressions = [];
          var mppSelectors = ['li.mpp__product .product_brief__image'];
          var sppWWWSelectors = ['.discover_more__product-quickshop'];
          var checkoutWWWSelectors = ['#works-well-with-wrapper img'];

          $(mppSelectors.join(', ') + ', ' + sppWWWSelectors.join(', ') + ', ' + checkoutWWWSelectors.join(', ')).each(function() {
            if ($(this).data('impressionTriggered') !== true && checkPosition($(this))) {
              var prodId = $(this).data('productid') || $(this).data('product-id');
              if (prodId && products) {
                prodObj = getProduct(prodId, products);

                if ($(this).is(mppSelectors.join(', '))) {
                  list = $(location).attr('pathname');
                } else if ($(this).is(sppWWWSelectors.join(', '))) {
                  list = 'SPP Related';
                } else if ($(this).is(checkoutWWWSelectors.join(', '))) {
                  list = 'Cart Additional List';
                }
                if (prodObj && prodObj.defaultSku) {
                  impressions.push({
                    name: prodObj.PROD_RGN_NAME,
                    id: prodObj.defaultSku.SKU_BASE_ID,
                    price: prodObj.defaultSku.PRICE,
                    list: list,
                    category: category,
                    variant: prodObj.defaultSku.SHADENAME || prodObj.defaultSku.PRODUCT_SIZE,
                    position: products.indexOf(prodObj)
                  });
                  $(this).data('impressionTriggered', true);
                }
              }
            }
          });
          if (impressions.length > 0) {
            $(document).trigger({
              type: 'ecommerce.btga',
              ecommerceData: {
                impressions: impressions
              },
              gtm_ecom_event_action: 'Product Impressions',
              gtm_ecom_event_non_interaction: 'True'
            });
          }
        }, scrollDebouncePeriod));

        // Product Teaser Click
        // For SPP-linked elements in MPPs and SPPs
        $(document).on('click', 'li.mpp__product a.product_brief__image-container, li.mpp__product .product_brief__headers, li.mpp__product .js-shopnow-button, li.discover_more__product .discover_more__product-url', function() {
          var prodId;
          var pageType = getPageType();

          if (pageType === 'MPP') {
            prodId = $(this).data('product-id') || $(this).closest('li.mpp__product').data('productid') || $(this).closest('li.mpp__product').data('product-id');
          } else if (pageType === 'SPP') {
            prodId = $(this).siblings('.js-discover-more-quickshop').data('productid');
          }

          if (prodId) {
            prodObj = getProduct(prodId, products);
            if (prodObj) {
              if (prodObj.defaultSku) {
                var skuObj = prodObj.defaultSku;
                if (skuObj) {
                  $(document).trigger({
                    type: 'ecommerce.btga',
                    ecommerceData: {
                      click: {
                        actionField: {
                          list: window.location.pathname
                        },
                        products: [{
                          name: prodObj.PROD_RGN_NAME,
                          id: skuObj.SKU_BASE_ID,
                          price: skuObj.PRICE,
                          category: category,
                          variant: skuObj.SHADENAME || skuObj.PRODUCT_SIZE || '',
                          position: products.indexOf(prodObj)
                        }]
                      }
                    },
                    gtm_ecom_event_action: 'Product Click',
                    gtm_ecom_event_non_interaction: 'False'
                  });
                }
              }
            }
          }
        });

        // SPP visit
        if ($('body.page-product').length && page_data['catalog-spp']['products'].length) {
          prodObj = getProduct(page_data['catalog-spp']['products'][0].PRODUCT_ID, products);

          if (prodObj) {
            list = 'SPP';
            if (document.referrer) {
              list = document.referrer.split(window.location.hostname)[1];
            }

            $(document).trigger({
              type: 'ecommerce.btga',
              ecommerceData: {
                detail: {
                  actionField: {
                    list: list
                  },
                  products: [{
                    name: prodObj.PROD_RGN_NAME,
                    id: prodObj.defaultSku.SKU_BASE_ID,
                    price: prodObj.defaultSku.PRICE,
                    category: category,
                    variant: prodObj.defaultSku.SHADENAME || prodObj.defaultSku.PRODUCT_SIZE,
                  }]
                }
              },
              gtm_ecom_event_action: 'Product Details',
              gtm_ecom_event_non_interaction: 'True'
            });
          }
        }

        // Add to cart
        // We already have events for this but as this is specific to Enhanced Ecommerce we've got a separate event. However we can piggyback on the event we created in addEventTriggers() above.
        $(document).on('productAddToCart.btga', function(event) {
          var prodId = $(event.target.activeElement).data('product-id');
          var skuBaseId = $(event.target.activeElement).data('sku-base-id');
          if (prodId && skuBaseId) {
            prodObj = getProduct(prodId, products);
            if (prodObj) {
              var skuObj = prodObj.defaultSku;
              for (var i = prodObj.skus.length - 1; i >= 0; i--) {
                if (skuBaseId === prodObj.skus[i].SKU_BASE_ID) {
                  skuObj = prodObj.skus[i];
                  break;
                }
              }
              if (skuObj) {
                $(document).trigger({
                  type: 'ecommerce.btga',
                  ecommerceData: {
                    add: {
                      products: [{
                        name: prodObj.PROD_RGN_NAME,
                        id: skuObj.SKU_BASE_ID,
                        price: skuObj.PRICE,
                        category: category,
                        variant: skuObj.SHADENAME || skuObj.PRODUCT_SIZE,
                        quantity: $(event.target.activeElement).data('qty') || 1
                      }]
                    }
                  },
                  gtm_ecom_event_action: 'Adding a Product to a Shopping Cart',
                  gtm_ecom_event_non_interaction: 'False'
                });
              }
            }
          }
        });

        $(document).on('cartProductQtyChange.btga', function(event) {
          var skuBaseID = event.skuBaseID;
          var delta = event.delta;
          var cartProducts = getCartProducts();
          var product;
          for (var i = cartProducts.length - 1; i >= 0; i--) {
            if (cartProducts[i].variant.toUpperCase() === 'SKU' + skuBaseID) {
              product = cartProducts[i];
            }
          }

          if (delta > 0) {
            product['quantity'] = delta;
            $(document).trigger({
              type: 'ecommerce.btga',
              ecommerceData: {
                add: {
                  products: [product]
                }
              },
              gtm_ecom_event_action: 'More quantity for a Shopping Cart product',
              gtm_ecom_event_non_interaction: 'False'
            });
          } else {
            product['quantity'] = delta * -1;
            $(document).trigger({
              type: 'ecommerce.btga',
              ecommerceData: {
                remove: {
                  products: [product]
                }
              },
              gtm_ecom_event_action: 'Less quantity for a Shopping Cart product',
              gtm_ecom_event_non_interaction: 'False'
            });
          }
        });

        // Cart visit
        cartVisitedGA = $.cookie('cartVisitedGA') ? 1 : 0;
        if ($('body#viewcart').length && !cartVisitedGA) {
          date = new Date();
          date.setTime(+date + 600000); //600s. For GA cart visited cookie to last 10 minutes
          window.document.cookie = 'cartVisitedGA=' + 1 + '; expires=' + date.toGMTString() + '; path=/';

          $(document).trigger({
            type: 'ecommerce.btga',
            ecommerceData: {
              checkout: {
                actionField: {step: 1},
                products: cartProducts
              }
            },
            gtm_ecom_event_action: 'Checkout Step 1',
            gtm_ecom_event_non_interaction: 'False'
          });
        }

        // Remove from cart
        $('.remove_link').on('mousedown', function() {
          var skuId = 'SKU' + $(this).closest('form').find('input[name=SKU_BASE_ID]').val();
          var product = [];

          for (var i = cartProducts.length - 1; i >= 0; i--) {
            if (cartProducts[i].variant.toUpperCase() === skuId) {
              product = cartProducts[i];
            }
          }

          $(document).trigger({
            type: 'ecommerce.btga',
            ecommerceData: {
              remove: {
                products: [product]
              }
            },
            gtm_ecom_event_action: 'Removing a Product from a Shopping Cart',
            gtm_ecom_event_non_interaction: 'False'
          });
        });

        //Samples visit
        if ($('body#samples').length) {
          $(document).trigger({
            type: 'ecommerce.btga',
            ecommerceData: {
              checkout: {
                actionField: {step: 2},
              }
            },
            gtm_ecom_event_action: 'Checkout Step 2 (Samples)',
            gtm_ecom_event_non_interaction: 'False'
          });
        }

        //Samples submit
        $('#checkout_samples').submit(function() {
          var samples = '';
          $('form#checkout_samples input:checked').each(function() {
            samples += $(this).val() + ',';
          });
          samples = samples.slice(0, -1);

          date = new Date();
          date.setTime(+date + 600000); //600s. For GA cart samples cookie to last 10 minutes
          window.document.cookie = 'cartSamplesGA=' + samples + '; expires=' + date.toGMTString() + '; path=/';
        });

        //Checkout Shipping submit
        $('#checkout_shipping').submit(function() {
          var shippingMethod = $('input[name=SHIP_METHOD_GROUP]:checked', '#delivery-options-group').val() || '';
          var acceptedTerms = $('#form--checkout_registration--field--TERMS:checkbox').length ? $('#form--checkout_registration--field--TERMS:checkbox').prop('checked') : true;

          if (shippingMethod && acceptedTerms) {
            var valid_pp_form = false;
            if (shippingMethod === '14') { //Pickpoint
              var name = $('input[name="SHIPFORM_FIRST_NAME"]').val();
              var surname = $('input[name="SHIPFORM_LAST_NAME"]').val();
              var mobile = $('input[name="SHIPFORM_PHONE1"]').val();
              var pickPointRegion = $('[name="PICK_POINT_REGION"]').val();
              var pickPointCity = $('[name="PICK_POINT_CITY"]').val();
              var pickPointID = $('[name="PICK_POINT_ID"]').val();

              if (name && surname && pickPointRegion && pickPointCity && pickPointID && parseInt(mobile) && mobile.length >= 10 && mobile.length <= 11) {
                valid_pp_form = true;
              }
            }

            if (shippingMethod === '11' || (shippingMethod === '14' && valid_pp_form)) {
              var samples = $.cookie('cartSamplesGA');
              var shippingMethodMap = {
                11: 'Сourier',
                14: 'Pickpoint'
              };
              var shippingOption = shippingMethod ? shippingMethodMap[shippingMethod] : '';
              $(document).trigger({
                type: 'ecommerce.btga',
                ecommerceData: {
                  checkout: {
                    actionField: {
                      step: 3,
                      option: shippingOption
                    },
                    products: cartProducts,
                    samples: samples
                  }
                },
                gtm_ecom_event_action: 'Checkout Step 3 (Shipping Details)',
                gtm_ecom_event_non_interaction: 'False'
              });
            }
          }
        });

        //Checkout Review visit
        if ($('body#review').length) {
          selectedPaymentInput = $('input[name=PAYMENT_OPTION]:checked', '#payment-options-section').val() || '';
          date = new Date();
          date.setTime(+date + 600000); //600s. For GA payment option cookie to last 10 minutes
          window.document.cookie = 'paymentOption=' + selectedPaymentInput + '; expires=' + date.toGMTString() + '; path=/';

          $(document).trigger({
            type: 'ecommerce.btga',
            ecommerceData: {
              checkout: {
                actionField: {
                  step: 4,
                },
              },
              products: cartProducts
            },
            gtm_ecom_event_action: 'Checkout Step 4 (Order Review)',
            gtm_ecom_event_non_interaction: 'False'
          });
        }

        // Purchase Confirmed
        if ($('body#confirm').length) {
          orderInfo = getOrderInfo();
          if ($.isEmptyObject(orderInfo)) {
            orderInfo.orderID = utag_data && utag_data.order_id ? utag_data.order_id : $('#confirmation-number .confirmation-panel-confirmation-number a').text() || '';
            orderInfo.total = utag_data && utag_data.order_merchandise_total ? utag_data.order_merchandise_total : '';
          }

          paymentOptionCode = $.cookie('paymentOption') || '';
          paymentOptionCode = paymentOptionCode.replace('PAYMENT_OPTION_', '');
          paymentMethodMap = {
            'CC': 'Card',
            'COD': 'Cash'
          };
          paymentOption = paymentOptionCode ? paymentMethodMap[paymentOptionCode] : '';

          $(document).trigger({
            type: 'ecommerce.btga',
            ecommerceData: {
              purchase: {
                actionField: {
                  step: 5,
                  id: orderInfo.orderID,
                  revenue: orderInfo.total,
                  option: paymentOption
                },
                products: cartProducts
              }
            },
            gtm_ecom_event_action: 'Checkout Step 5 (Purchase)',
            gtm_ecom_event_non_interaction: 'False'
          });
        }
      }

      function getCategory(tmsPageName) {
        var categoryName = false;

        if (tmsPageName['pageType'] === 'MPP') {
          var pageID = tmsPageName['pageID'];

          if (pageID.indexOf('MPP | ') === 0) {
            pageID = pageID.split('MPP | ').pop();
          } else if (pageID.indexOf('MPP ') === 0) {
            pageID = pageID.split('MPP ').pop();
          }
          categoryName = pageID !== '' ? pageID : tmsPageName['categoryName'];
        } else {
          categoryName = tmsPageName['categoryName'];
        }

        return categoryName;
      }

      function getProducts() {
        var products = [];
        var page_data_vars = ['custom-mpp', 'catalog-mpp', 'catalog-spp', 'catalog-spp-workswith'];

        for (var i = page_data_vars.length - 1; i >= 0; i--) {
          if (page_data[page_data_vars[i]] && page_data[page_data_vars[i]].products) {
            products = products.concat(page_data[page_data_vars[i]].products);
          }
          if (page_data[page_data_vars[i]] && page_data[page_data_vars[i]].categories && page_data[page_data_vars[i]].categories[0].products) {
            products = products.concat(page_data[page_data_vars[i]].categories[0].products);
          }
        }
        if (products.length) {
          return products;
        }
        return false;
      }

      function getOrderInfo() {
        var orderInfo = {};

        if (tms_page_data && tms_page_data.tms_order_info) {
          orderInfo.orderID = tms_page_data.tms_order_info.transOrderID;
          orderInfo.total = tms_page_data.tms_order_info.orderTotal;
        }
        return orderInfo;
      }

      function getCartProducts() {
        var cartProducts = [];
        var i;

        if (tms_page_data && tms_page_data.tms_cart_items) {
          for (i = tms_page_data.tms_cart_items.length - 1; i >= 0; i--) {
            cartProducts.push({
              name: decodeURIComponent(tms_page_data.tms_cart_items[i].productName),
              id: tms_page_data.tms_cart_items[i].productID.replace('PROD', ''),
              price: tms_page_data.tms_cart_items[i].productPrice,
              category: decodeURIComponent(tms_page_data.tms_cart_items[i].categoryName),
              variant: 'sku' + tms_page_data.tms_cart_items[i].sku,
              quantity: tms_page_data.tms_cart_items[i].productQuantity
            });
          }
        } else if (tms_page_data && tms_page_data.tms_gtm_info && tms_page_data.tms_gtm_info.order_items) {
          for (i = tms_page_data.tms_gtm_info.order_items.length - 1; i >= 0; i--) {
            cartProducts.push({
              name: decodeURIComponent(tms_page_data.tms_gtm_info.order_items[i].productName),
              id: tms_page_data.tms_gtm_info.order_items[i].productID.replace('PROD', ''),
              price: tms_page_data.tms_gtm_info.order_items[i].productPrice,
              category: decodeURIComponent(tms_page_data.tms_gtm_info.order_items[i].categoryName),
              variant: tms_page_data.tms_gtm_info.order_items[i].sku.toLowerCase(),
              quantity: tms_page_data.tms_gtm_info.order_items[i].productQuantity
            });
          }
        } else if (utag_data && utag_data.cart_product_sku) {
          for (i = utag_data.cart_product_sku.length - 1; i >= 0; i--) {
            cartProducts.push({
              name: decodeURIComponent(utag_data.cart_product_name ? utag_data.cart_product_name[i] : utag_data.product_name ? utag_data.product_name[i] : ''),
              id: (utag_data.cart_product_id ? utag_data.cart_product_id[i] : utag_data.product_id ? utag_data.product_id[i] : '').replace('PROD', ''),
              price: utag_data.cart_product_price ? utag_data.cart_product_price[i] : utag_data.product_price ? utag_data.product_price[i] : '',
              variant: utag_data.cart_product_sku ? utag_data.cart_product_sku[i] : utag_data.product_sku ? utag_data.product_sku[i] : '',
              quantity: utag_data.cart_product_quantity ? utag_data.cart_product_quantity[i] : utag_data.product_quantity ? utag_data.product_quantity[i] : ''
            });
          }
        }
        return cartProducts;
      }

      function getProduct(prodId, products) {
        if (products) {
          for (var i = products.length - 1; i >= 0; i--) {
            if (products[i].PRODUCT_ID === prodId) {
              return products[i];
            }
          }
        }
        return false;
      }

      function checkPosition($el) {
        // element's coordinates
        var el_position = $el.offset();
        var el_top = el_position.top;
        var el_left = el_position.left;
        var el_width = $el.width();
        var el_height = $el.height();

        var top_scroll = $(document).scrollTop();
        var left_scroll = $(document).scrollLeft();
        var screen_width = $(window).width();
        var screen_height = $(window).height();

        // visible area corners coordinates
        var see_x1 = left_scroll;
        var see_x2 = screen_width + left_scroll;
        var see_y1 = top_scroll;
        var see_y2 = screen_height + top_scroll;

        // target element corners coordinates
        var el_x1 = el_left;
        var el_x2 = el_left + el_height;
        var el_y1 = el_top;
        var el_y2 = el_top + el_width;

        // checking - is element fully visible or not
        if (el_x1 >= see_x1 && el_x2 <= see_x2 && el_y1 >= see_y1 && el_y2 <= see_y2) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
})(jQuery);
